import Reviews from '@/components/home/reviews/Reviews.vue';
import Form from '@/components/form/Form.vue';
import VueSlickCarousel from 'vue-slick-carousel'
import getProjects from "@/api/projects";
import ScrollToBtn from '@/components/common/scrollto/ScrollToBtn.vue'

export default {
	components: {Reviews, Form, VueSlickCarousel, ScrollToBtn},
	metaInfo() {
		return {
			title: this.currentWork?.metaTitle ?? 'Work title',
			meta: [
				{name: 'description', content: this.currentWork?.metaDescription ?? 'Work description'},
				{name: 'keywords', content: this.currentWork?.metaKeywords ?? 'Work keywords'},
			]
		}
	},
	data() {
		return {
			settings: {
				arrows: true,
				dots: true,
			},
			showVideo: false,
			loading: false
		}
	},
	computed: {
		projects() {
			if (this.state.projects) {
				return this.state.projects.data
			}
			return []
		},
		currentWork() {
			if (this.state.projects.data) {
				return this.state.projects.data.find(service => service.urlName === this.$route.params.urlName)
			}
			return []
		},
		video() {
			return !!this.currentWork?.file?.length
		},
		images() {
			if (this.currentWork?.images) {
				if (typeof this.currentWork.images === 'string') {
					return JSON.parse(this.currentWork.images)
				}
				return this.currentWork.images
			}
			return []
		},
		mobileImages() {
			if (this.currentWork?.mobileImages) {
				if (typeof this.currentWork.mobileImages === 'string') {
					return JSON.parse(this.currentWork.mobileImages)
				}
				return this.currentWork.mobileImages
			}
			return []
		},
	},
	methods: {
		playVideo() {
			if (this.showVideo === false) {
				this.$refs.videoRef.play();
				this.showVideo = true
			} else {
				this.$refs.videoRef.pause();
				this.showVideo = false
			}
		},
		updatePaused() {
			this.loading = false
		},
		showLoading(){
			this.loading = true
		}
},
	created() {
		getProjects.getAll(this);
	},
}
